import * as React from "react";
import { styled, useTheme } from "@mui/material/styles";
import useMediaQuery from "@mui/material/useMediaQuery";
import Box from "@mui/material/Box";
import Drawer from "@mui/material/Drawer";
import CssBaseline from "@mui/material/CssBaseline";
import MuiAppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import List from "@mui/material/List";
import Typography from "@mui/material/Typography";
import Divider from "@mui/material/Divider";
import IconButton from "@mui/material/IconButton";
import MenuIcon from "@mui/icons-material/Menu";
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import { Route, Routes, useLocation, useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import { logout } from "../slices/authSlice";
import { Link } from "react-router-dom";
import { Image } from "react-bootstrap";

// components
import Profile from "../pages/Profile";
import MapLocation from "../pages/MapLocation";
import Attendance from "../pages/Attendance";
import Roster from "../pages/Roster/Roster";
import Devicelocation from "../pages/Devicelocation";

import { IoMdLogOut } from "react-icons/io";
import { FaRegFileAlt } from "react-icons/fa";
import { FaCalendarAlt } from "react-icons/fa";
import { IoTimer } from "react-icons/io5";
import { LuCalendarClock } from "react-icons/lu";

import { useDispatch } from "react-redux";

import AddTime from "../pages/Addtime/AddTime";
import AddNote from "../pages/Addnote/AddNote";
import Timesheet from "../pages/Timesheet/Timesheet";
import TimesheetDetails from "../pages/Timesheet/TimesheetDetails";
import Logout from "../pages/Logout";

const drawerWidth = 240;

const Main = styled("main", { shouldForwardProp: (prop) => prop !== "open" })(
  ({ theme, open }) => ({
    flexGrow: 1,
    padding: theme.spacing(3),
    transition: theme.transitions.create("margin", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    marginLeft: `-${drawerWidth}px`,
    ...(open && {
      transition: theme.transitions.create("margin", {
        easing: theme.transitions.easing.easeOut,
        duration: theme.transitions.duration.enteringScreen,
      }),
      marginLeft: 0,
    }),
  })
);

const AppBar = styled(MuiAppBar, {
  shouldForwardProp: (prop) => prop !== "open",
})(({ theme, open }) => ({
  transition: theme.transitions.create(["margin", "width"], {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  ...(open && {
    width: `calc(100% - ${drawerWidth}px)`,
    marginLeft: `${drawerWidth}px`,
    transition: theme.transitions.create(["margin", "width"], {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen,
    }),
  }),
}));

const DrawerHeader = styled("div")(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  padding: theme.spacing(0, 1),
  // necessary for content to be below app bar
  ...theme.mixins.toolbar,
  justifyContent: "flex-end",
}));

export default function Sidenav() {
  const { isAuthenticated } = useSelector((state) => state.auth);
  const user = useSelector((state) => state.user);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [pageTitle, setPageTitle] = React.useState("");
  const location = useLocation();

  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
  const [open, setOpen] = React.useState(!isMobile); // Open by default on web, closed on mobile

  React.useEffect(() => {
    if (location.pathname === "/attendance") {
      setPageTitle("Attendance");
    } else if (location.pathname === "/profile") {
      setPageTitle("Profile");
    } else if (location.pathname === "/roster") {
      setPageTitle("Roster");
    } else if (location.pathname === "/timesheet") {
      setPageTitle("Timesheet");
    }

    // Handle drawer based on screen size
    if (isMobile) {
      setOpen(false);
    }
  }, [location, isMobile]);

  const handleDrawerOpen = () => {
    setOpen(true);
  };

  const handleDrawerClose = () => {
    setOpen(false);
  };

  const handleLogout = () => {
    dispatch(logout());
    navigate("/logout");
  };

  const handleListItemClick = (path) => {
    navigate(path);
    if (isMobile) {
      handleDrawerClose();
    }
  };

  return (
    <Box sx={{ display: "flex" }}>
      <CssBaseline />
      <AppBar sx={{ background: "#1959bb" }} position="fixed" open={open}>
        <Toolbar>
          <IconButton
            color="inherit"
            aria-label="open drawer"
            onClick={handleDrawerOpen}
            edge="start"
            sx={{ mr: 2, ...(open && { display: "none" }) }}
          >
            <MenuIcon />
          </IconButton>
          {open && (
            <IconButton
              onClick={handleDrawerClose}
              style={{ color: "white", marginLeft: "-20px" }}
            >
              <MenuIcon />
            </IconButton>
          )}
          <Typography variant="h6" noWrap component="div">
            <b>{pageTitle}</b>
          </Typography>
        </Toolbar>
      </AppBar>
      <Drawer
        sx={{
          width: drawerWidth,
          flexShrink: 0,
          "& .MuiDrawer-paper": {
            width: drawerWidth,
            boxSizing: "border-box",
            background: "#1959bb",
          },
        }}
        id="sidebar_drawer"
        variant="persistent"
        anchor="left"
        open={open}
      >
        <DrawerHeader>
          <Link to={"/attendance"}>
            <Image
              style={{
                margin: 10,
              }}
              src={require("../assets/images/logo.png")}
              width="170"
              height="40"
            />
          </Link>
          {/* <IconButton onClick={handleDrawerClose} style={{ color: "white" }}>
            <ChevronLeftIcon />
          </IconButton> */}
        </DrawerHeader>
        <Divider />
        <List>
          <ListItem key="Attendance" disablePadding>
            <ListItemButton
              onClick={() => handleListItemClick("/attendance")}
              selected={location.pathname === "/attendance"}
            >
              <ListItemIcon>
                <FaCalendarAlt size="1em" />
              </ListItemIcon>
              <ListItemText className="sidebar-text" primary="Attendance" />
            </ListItemButton>
          </ListItem>

          <ListItem key="Profile" disablePadding>
            <ListItemButton
              onClick={() => handleListItemClick("/profile")}
              selected={location.pathname === "/profile"}
            >
              <ListItemIcon>
                <FaRegFileAlt size="1em" />
              </ListItemIcon>
              <ListItemText className="sidebar-text" primary="Profile" />
            </ListItemButton>
          </ListItem>

          <ListItem key="Roster" disablePadding>
            <ListItemButton
              onClick={() => handleListItemClick("/roster")}
              selected={location.pathname === "/roster"}
            >
              <ListItemIcon>
                <IoTimer size="1.2em" />
              </ListItemIcon>
              <ListItemText className="sidebar-text" primary="Roster" />
            </ListItemButton>
          </ListItem>

          {/* <ListItem key="Roster" disablePadding>
            <ListItemButton
              onClick={() => handleListItemClick("/timesheet")}
              selected={location.pathname === "/timesheet"}
            >
              <ListItemIcon>
                <LuCalendarClock size="1.2em" />
              </ListItemIcon>
              <ListItemText className="sidebar-text" primary="Timesheet" />
            </ListItemButton>
          </ListItem> */}

          {isAuthenticated && (
            <ListItem key="Logout" disablePadding>
              <ListItemButton onClick={handleLogout}>
                <ListItemIcon>
                  <IoMdLogOut size="1em" />
                </ListItemIcon>
                <ListItemText className="sidebar-text" primary="Logout" />
              </ListItemButton>
            </ListItem>
          )}
        </List>
        <Divider />
      </Drawer>
      <Main
        id="main_div"
        className={open ? "menu_open" : "menu_close"}
        open={open}
      >
        <DrawerHeader />
        <Routes>
          <Route path="/" element={<Attendance />} />
          <Route path="/attendance" element={<Attendance />} />
          <Route path="/devicelocation" element={<Devicelocation />} />
          <Route path="/profile" element={<Profile />} />
          <Route path="/roster" element={<Roster />} />
          <Route path="/timesheetdetails" element={<TimesheetDetails />} />
          <Route path="/timesheet" element={<Timesheet />} />
          <Route path="/add-time" element={<AddTime />} />
          <Route path="/add-note" element={<AddNote />} />
          <Route path="/map" element={<MapLocation />} />
          <Route path="/logout" element={<Logout />} />
        </Routes>
      </Main>
    </Box>
  );
}
