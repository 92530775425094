import React, { useEffect, useState } from "react";
import { Card, Col, Row, Button, Form, Modal, Alert } from "react-bootstrap";
import "./addnote.css";
import axios from "axios";
import { useDispatch, useSelector } from "react-redux";
import CustomBackButton from "../../components/CustomBackButton";
import { useLocation } from "react-router-dom";

export const getApi = () => {
  return axios.create({
    baseURL: "https://roster.coddapps.com.au/api/",
    headers: {
      "Content-Type": "application/json",
      Validate: "y2s4pyj52nzr49jnuxxgqk5jtj28cj",
    },
  });
};

// Function to convert date format
const formatDate = (dateString) => {
  const [day, date, month] = dateString.split(" ");
  const year = new Date().getFullYear(); // Get the current year
  const monthMap = {
    Jan: "01",
    Feb: "02",
    Mar: "03",
    Apr: "04",
    May: "05",
    Jun: "06",
    Jul: "07",
    Aug: "08",
    Sep: "09",
    Oct: "10",
    Nov: "11",
    Dec: "12",
  };

  const formattedDate = `${year}-${monthMap[month]}-${String(date).padStart(
    2,
    "0"
  )}`;
  return formattedDate;
};

const AddNote = () => {
  const location = useLocation();
  const selectedDate = location.state?.selectedDate;
  const formattedDate = selectedDate ? formatDate(selectedDate) : "";

  const [noteText, setNoteText] = useState("");
  const [showSuccessModal, setShowSuccessModal] = useState(false);
  const [showWarning, setShowWarning] = useState(false);

  const user = useSelector((state) => state.user);
  const dispatch = useDispatch();

  const handleInputChange = (event) => {
    setNoteText(event.target.value);
    // Hide the warning message when the user types something
    if (event.target.value.trim() !== "") {
      setShowWarning(false);
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (noteText.trim() === "") {
      setShowWarning(true);
    } else {
      setShowWarning(false);

      console.log("Entered note text:", noteText);

      const api = getApi();
      const data = {
        requesttype: "4",
        // locationid: "S01002ANA",
        // userid: "101",
        locationid: user.LocationId,
        userid: user.UserId,
        sdate: formattedDate,
        note: noteText,
      };

      try {
        // Make the POST request to the API
        const response = await api.post("Timesheet/", data);

        // console.log("Response from API:", response.data);
        // Show success modal and reset the input field
        setShowSuccessModal(true);
        setNoteText("");
        setTimeout(() => {
          setShowSuccessModal(false);
        }, 2000);
      } catch (error) {
        console.error("Error submitting note:", error);
      }
    }
  };

  const fetchTimesheetUpdate = async () => {
    const data = {
      requesttype: "9",
      // locationid: "S01002ANA",
      // userid: "101",
      locationid: user.LocationId,
      userid: user.UserId,
      sdate: formattedDate,
    };

    try {
      const response = await getApi().post("Timesheet/", data);
      // console.log("Timesheet update Response Data:", response.data.data.NOTE_1);
      setNoteText(response.data.data.NOTE_1 || "");
    } catch (error) {
      console.error("Error fetching timesheet update:", error);
    }
  };

  useEffect(() => {
    fetchTimesheetUpdate();
  }, []);

  const handleCloseModal = () => {
    setShowSuccessModal(false);
  };

  return (
    <>
      <Row>
        <Col sm={1}></Col>
        <Col sm={10} className="custom-roster-row">
          <div className="text-end">
            <CustomBackButton />
          </div>
          <Card className="main-card-addnote">
            <h6 className="mt-3 mb-3">Add Note</h6>
            <Form onSubmit={handleSubmit}>
              <Form.Group>
                <Form.Control
                  as="textarea"
                  placeholder="Enter your note"
                  value={noteText}
                  onChange={handleInputChange}
                  className="mb-2 addnote-custom-form"
                  style={{ height: "150px" }}
                />
              </Form.Group>

              {showWarning && (
                <Alert variant="warning" className="mt-2 addnote-custom-form">
                  Please enter a note.
                </Alert>
              )}

              <Button variant="primary" type="submit">
                Submit
              </Button>
            </Form>
          </Card>
        </Col>
      </Row>

      {/* Success Modal */}
      <Modal show={showSuccessModal} onHide={handleCloseModal} centered>
        <Modal.Header closeButton>
          <Modal.Title>Success</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <p>Successfully submitted the Add Note form!</p>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="primary" onClick={handleCloseModal}>
            Close
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default AddNote;
