import React, { useState, useEffect } from "react";
import Card from "react-bootstrap/Card";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import { Col, Row } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import Form from "react-bootstrap/Form";
import Cookies from "js-cookie";
import customCloseButtonImage from "../assets/images/close-button1.png";
import moment from "moment";
import customClockImage from "../assets/images/clock.png";
import { BsThreeDotsVertical } from "react-icons/bs";
import { Typography, CircularProgress } from "@mui/material";
import TextField from "@mui/material/TextField";
import Autocomplete from "@mui/material/Autocomplete";
import "./attendance.css";

import axios from "axios";
import { useDispatch, useSelector } from "react-redux";
import TimeModal from "../components/TimeModal";
import DistanceAlertModal from "../components/DistanceAlertModal";

export const getApi = () => {
  return axios.create({
    baseURL: "https://roster.coddapps.com.au/api/",
    headers: {
      "Content-Type": "application/json",
      Validate: "y2s4pyj52nzr49jnuxxgqk5jtj28cj",
    },
  });
};

const Devicelocation = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const getapi = getApi();

  const fetchPracticeLocations = () => {
    // Retrieve cookies
    const latlng = Cookies.get("LATLNG");
    const Lid = Cookies.get("location_id");
    const Uid = Cookies.get("userid");
    const ULid = Cookies.get("userlocation_Id");

    if (latlng && Lid && Uid && ULid) {
      // All cookies are available, navigate to attendance
      navigate("/attendance");
    } else {
      console.error("Required cookies not found");
    }
  };

  useEffect(() => {
    const currentUrl = window.location.href;
    const urlObj = new URL(currentUrl);

    // Extract parameters from URL
    const latlng = urlObj.searchParams.get("latlng");
    const Lid = urlObj.searchParams.get("Lid");
    const Uid = urlObj.searchParams.get("Uid");
    const ULid = urlObj.searchParams.get("ULid");

    if (latlng && Lid && Uid && ULid) {
      // Store the parameters in cookies
      Cookies.set("LATLNG", latlng);
      Cookies.set("location_id", Lid);
      Cookies.set("userid", Uid);
      Cookies.set("userlocation_Id", ULid);

      // Fetch practice locations
      fetchPracticeLocations();
    } else {
      console.error("Required URL parameters not found");
    }
  }, [navigate]);

  return (
    <>
      <Row>
        <Col md={1}></Col>
        <Col md={10} className="custom-attendance-row">
          <p>Loading...</p>
        </Col>
        <Col md={1}></Col>
      </Row>
    </>
  );
};

export default Devicelocation;
