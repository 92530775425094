// userSlice.js
import { createSlice } from "@reduxjs/toolkit";

const userSlice = createSlice({
  name: "user",
  initialState: {
    username: "",
    password: "",
    LocationId: "",
    Location_Name: "",
    ImagePath: "",
    MailId: "",
    MobileNo: "",
    Name: "",
    UserId: "",
    Id: "",
    LATLNG: "",
  },
  reducers: {
    setUser: (state, action) => {
      const {
        email,
        password,
        LocationId,
        ImagePath,
        MailId,
        MobileNo,
        Name,
        UserId,
        Id,
        Location_Name,
        LATLNG,
        username,
        is_diect
      } = action.payload;
      // Only update the fields provided in the payload
      if (username !== undefined) state.username = username; // Handle username
      if (email !== undefined) state.username = email;
      if (password !== undefined) state.password = password;
      if (LocationId !== undefined) state.LocationId = LocationId;
      if (Location_Name !== undefined) state.Location_Name = Location_Name;
      if (ImagePath !== undefined) state.ImagePath = ImagePath;
      if (MailId !== undefined) state.MailId = MailId;
      if (MobileNo !== undefined) state.MobileNo = MobileNo;
      if (Name !== undefined) state.Name = Name;
      if (UserId !== undefined) state.UserId = UserId;
      if (Id !== undefined) state.Id = Id;
      if (LATLNG !== undefined) state.LATLNG = LATLNG;
      if (is_diect !== undefined) state.is_diect = is_diect;
    },
  },
});

export const { setUser } = userSlice.actions;
export default userSlice.reducer;
