import React, { useState, useEffect } from "react";
import { Card, Col, Row, Button, Form, Modal } from "react-bootstrap";
import "./addtime.css";
import axios from "axios";
import { useDispatch, useSelector } from "react-redux";
import CustomBackButton from "../../components/CustomBackButton";
import { useLocation } from "react-router-dom";

export const getApi = () => {
  return axios.create({
    baseURL: "https://roster.coddapps.com.au/api/",
    headers: {
      "Content-Type": "application/json",
      Validate: "y2s4pyj52nzr49jnuxxgqk5jtj28cj",
    },
  });
};

// Function to convert date format
const formatDate = (dateString) => {
  const [day, date, month] = dateString.split(" ");
  const year = new Date().getFullYear(); // Get the current year
  const monthMap = {
    Jan: "01",
    Feb: "02",
    Mar: "03",
    Apr: "04",
    May: "05",
    Jun: "06",
    Jul: "07",
    Aug: "08",
    Sep: "09",
    Oct: "10",
    Nov: "11",
    Dec: "12",
  };

  const formattedDate = `${year}-${monthMap[month]}-${String(date).padStart(
    2,
    "0"
  )}`;
  return formattedDate;
};

// Function to convert 24-hour time to 12-hour time with AM/PM and padded 08:00 format
const convertTo12HourFormat = (time24) => {
  let [hours, minutes] = time24.split(":");
  hours = parseInt(hours, 10);

  const period = hours >= 12 ? "PM" : "AM"; // Determine AM or PM
  hours = hours % 12 || 12; // Convert to 12-hour format, handling midnight and noon
  hours = String(hours).padStart(2, "0"); // Ensure hours have two digits
  minutes = minutes.padStart(2, "0"); // Ensure minutes have two digits

  return `${hours}:${minutes} ${period}`; // Return time in 08:00 AM/PM format
};

const AddTime = () => {
  const location = useLocation();
  const selectedDate = location.state?.selectedDate;
  const formattedDate = selectedDate ? formatDate(selectedDate) : "";

  const [selectedOption, setSelectedOption] = useState("");
  const [startTime, setStartTime] = useState("");
  const [endTime, setEndTime] = useState("");
  const [breakStartTime, setBreakStartTime] = useState("");
  const [breakEndTime, setBreakEndTime] = useState("");
  const [overStartTime, setOverStartTime] = useState("");
  const [overEndTime, setOverEndTime] = useState("");
  const [showSuccessModal, setShowSuccessModal] = useState(false);
  const [successMessage, setSuccessMessage] = useState(""); // For success message
  const [errorMessage, setErrorMessage] = useState(""); // For error handling

  // console.log("Received Selected Date in AddTime:", formattedDate);

  const user = useSelector((state) => state.user);
  const dispatch = useDispatch();

  const handleOptionChange = (event) => {
    setSelectedOption(event.target.value);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    let data; // Declare the variable to hold data

    if (selectedOption === "work") {
      data = {
        requesttype: "1",
        // locationid: "S01002ANA",
        // userid: "101",
        locationid: user.LocationId,
        userid: user.UserId,
        sdate: formattedDate,
        starttime: convertTo12HourFormat(startTime),
        endtime: convertTo12HourFormat(endTime),
      };
      setSuccessMessage("Successfully submitted the Add Work Time form!");
    } else if (selectedOption === "unpaid-break") {
      data = {
        requesttype: "2",
        // locationid: "S01002ANA",
        // userid: "101",
        locationid: user.LocationId,
        userid: user.UserId,
        sdate: formattedDate,
        breakstarttime: convertTo12HourFormat(breakStartTime),
        breakendtime: convertTo12HourFormat(breakEndTime),
      };
      setSuccessMessage("Successfully submitted the Unpaid Break form!");
    } else if (selectedOption === "overtime") {
      data = {
        requesttype: "3",
        // locationid: "S01002ANA",
        // userid: "101",
        locationid: user.LocationId,
        userid: user.UserId,
        sdate: formattedDate,
        overstarttime: convertTo12HourFormat(overStartTime),
        overendtime: convertTo12HourFormat(overEndTime),
      };
      setSuccessMessage("Successfully submitted the Overtime form!");
    } else {
      console.log("Selected option is not valid.");
      return; // Exit if not handling other options
    }

    try {
      const response = await getApi().post("Timesheet/", data);
      // console.log("Response:", response.data); // Log the response
      setShowSuccessModal(true); // Show success modal
      setTimeout(() => {
        setShowSuccessModal(false);
      }, 2000);
    } catch (error) {
      console.error("Error submitting the form:", error);
      setErrorMessage("Failed to submit the form."); // Set error message
    }
  };

  const fetchTimesheetUpdateold = async () => {
    const data = {
      requesttype: "9",
      // locationid: "S01002ANA",
      // userid: "101",
      locationid: user.LocationId,
      userid: user.UserId,
      sdate: formattedDate,
    };

    try {
      const response = await getApi().post("Timesheet/", data);
      // console.log("Timesheet update Response Data:", response.data.data);
      // console.log("Timesheet update start Time", response.data.data.START_TIME);
      // console.log("Timesheet update END Time", response.data.data.END_TIME);
      // console.log("Timesheet break start Time", response.data.data.BREAK_STIME);
      // console.log("Timesheet break end Time", response.data.data.BREAK_ETIME);
      // console.log("Timesheet over start Time", response.data.data.OVER_STIME);
      // console.log("Timesheet over end Time", response.data.data.OVER_ETIME);
      // Check if the selected option is 'work' and update startTime if START_TIME exists
      if (selectedOption === "work" && response.data.data.START_TIME) {
        // Convert START_TIME to 24-hour format if necessary
        const startTime24HourFormat = convertTo24HourFormat(
          response.data.data.START_TIME
        );
        setStartTime(startTime24HourFormat); // Set the state to the converted start time
        // console.log("Start Time set to:", startTime24HourFormat);
      } else {
        console.warn(
          "START_TIME is undefined or selected option is not 'work'."
        );
      }
    } catch (error) {
      console.error("Error:", error);
    }
  };

  const fetchTimesheetUpdate = async () => {
    const data = {
      requesttype: "9",
      // locationid: "S01002ANA",
      // userid: "101",
      locationid: user.LocationId,
      userid: user.UserId,
      sdate: formattedDate,
    };

    try {
      const response = await getApi().post("Timesheet/", data);
      console.log("Timesheet update Response Data:", response.data.data);

      const {
        START_TIME,
        END_TIME,
        BREAK_STIME,
        BREAK_ETIME,
        OVER_STIME,
        OVER_ETIME,
      } = response.data.data;

      // Display and convert START_TIME if it exists
      if (START_TIME) {
        const startTime24HourFormat = convertTo24HourFormat(START_TIME);
        setStartTime(startTime24HourFormat);
        console.log("Start Time set to:", startTime24HourFormat);
      } else {
        console.warn("START_TIME is undefined.");
      }

      // Display and convert END_TIME if it exists
      if (END_TIME) {
        const endTime24HourFormat = convertTo24HourFormat(END_TIME);
        setEndTime(endTime24HourFormat);
        console.log("End Time set to:", endTime24HourFormat);
      } else {
        console.warn("END_TIME is undefined.");
      }

      // Display and convert BREAK_STIME if it exists
      if (BREAK_STIME) {
        const breakStartTime24HourFormat = convertTo24HourFormat(BREAK_STIME);
        setBreakStartTime(breakStartTime24HourFormat);
        console.log("Break Start Time set to:", breakStartTime24HourFormat);
      } else {
        console.warn("BREAK_STIME is undefined.");
      }

      // Display and convert BREAK_ETIME if it exists
      if (BREAK_ETIME) {
        const breakEndTime24HourFormat = convertTo24HourFormat(BREAK_ETIME);
        setBreakEndTime(breakEndTime24HourFormat);
        console.log("Break End Time set to:", breakEndTime24HourFormat);
      } else {
        console.warn("BREAK_ETIME is undefined.");
      }

      // Display and convert OVER_STIME if it exists
      if (OVER_STIME) {
        const overStartTime24HourFormat = convertTo24HourFormat(OVER_STIME);
        setOverStartTime(overStartTime24HourFormat);
        console.log("Over Start Time set to:", overStartTime24HourFormat);
      } else {
        console.warn("OVER_STIME is undefined.");
      }

      // Display and convert OVER_ETIME if it exists
      if (OVER_ETIME) {
        const overEndTime24HourFormat = convertTo24HourFormat(OVER_ETIME);
        setOverEndTime(overEndTime24HourFormat);
        console.log("Over End Time set to:", overEndTime24HourFormat);
      } else {
        console.warn("OVER_ETIME is undefined.");
      }
    } catch (error) {
      console.error("Error fetching timesheet update:", error);
    }
  };

  useEffect(() => {
    if (selectedOption) {
      fetchTimesheetUpdate();
    }
  }, [selectedOption]); // Fetch when selectedOption changes

  // Function to convert 12-hour format to 24-hour format
  const convertTo24HourFormat = (time12) => {
    const [time, modifier] = time12.split(" ");
    let [hours, minutes] = time.split(":");

    if (modifier === "PM" && hours !== "12") {
      hours = parseInt(hours, 10) + 12;
    } else if (modifier === "AM" && hours === "12") {
      hours = "00";
    }

    return `${String(hours).padStart(2, "0")}:${minutes}`; // Return in HH:mm format
  };

  const handleCloseModal = () => {
    setShowSuccessModal(false);
  };

  return (
    <>
      <Row>
        <Col sm={1}></Col>
        <Col sm={10} className="custom-roster-row">
          <div className="text-end">
            <CustomBackButton />
          </div>
          <Card className="main-card-addtime">
            <h6 className="mt-3 mb-3">Add Time</h6>
            <Form onSubmit={handleSubmit}>
              <Form.Group>
                <Form.Check
                  type="radio"
                  label="Work"
                  value="work"
                  checked={selectedOption === "work"}
                  onChange={handleOptionChange}
                  className="mb-2"
                />
                <Form.Check
                  type="radio"
                  label="Unpaid Break"
                  value="unpaid-break"
                  checked={selectedOption === "unpaid-break"}
                  onChange={handleOptionChange}
                  className="mb-2"
                />
                <Form.Check
                  type="radio"
                  label="Overtime"
                  value="overtime"
                  checked={selectedOption === "overtime"}
                  onChange={handleOptionChange}
                  className="mb-3"
                />
              </Form.Group>

              {selectedOption === "work" && (
                <>
                  <Form.Group className="mb-2">
                    <Form.Label>Start Time (AM)</Form.Label>
                    <Form.Control
                      type="time"
                      value={startTime}
                      className="addtime-custom-form"
                      onChange={(e) => setStartTime(e.target.value)}
                      required
                    />
                  </Form.Group>
                  <Form.Group className="mb-2">
                    <Form.Label>End Time (PM)</Form.Label>
                    <Form.Control
                      type="time"
                      value={endTime}
                      className="addtime-custom-form"
                      onChange={(e) => setEndTime(e.target.value)}
                      required
                    />
                  </Form.Group>
                </>
              )}

              {selectedOption === "unpaid-break" && (
                <>
                  <Form.Group className="mb-2">
                    <Form.Label>Break Start Time</Form.Label>
                    <Form.Control
                      type="time"
                      value={breakStartTime}
                      className="addtime-custom-form"
                      onChange={(e) => setBreakStartTime(e.target.value)}
                      required
                    />
                  </Form.Group>
                  <Form.Group className="mb-2">
                    <Form.Label>Break End Time</Form.Label>
                    <Form.Control
                      type="time"
                      value={breakEndTime}
                      className="addtime-custom-form"
                      onChange={(e) => setBreakEndTime(e.target.value)}
                      required
                    />
                  </Form.Group>
                </>
              )}

              {selectedOption === "overtime" && (
                <>
                  <Form.Group className="mb-2">
                    <Form.Label>Overtime Start Time</Form.Label>
                    <Form.Control
                      type="time"
                      value={overStartTime}
                      className="addtime-custom-form"
                      onChange={(e) => setOverStartTime(e.target.value)}
                      required
                    />
                  </Form.Group>
                  <Form.Group className="mb-2">
                    <Form.Label>Overtime End Time</Form.Label>
                    <Form.Control
                      type="time"
                      value={overEndTime}
                      className="addtime-custom-form"
                      onChange={(e) => setOverEndTime(e.target.value)}
                      required
                    />
                  </Form.Group>
                </>
              )}

              <Button variant="primary" type="submit">
                Submit
              </Button>
            </Form>
          </Card>
        </Col>
      </Row>

      {/* Success Modal */}
      <Modal show={showSuccessModal} onHide={handleCloseModal} centered>
        <Modal.Header closeButton>
          <Modal.Title>Success</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {/* <p>Successfully submitted the Add Time form!</p> */}
          <p>{successMessage}</p>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="primary" onClick={handleCloseModal}>
            Close
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default AddTime;
