// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.custom-timesheetdt-row {
  margin: 10px 0px;
}

.main-card {
  margin: 10px 0px;
}

.custom-timesheetdt-title h6 {
  font-weight: 900;
  font-size: 20px !important;
}
.timesheetdt-details p {
  margin-bottom: 3px !important;
  font-size: 18px;
  padding-left: 6px !important;
}

.pending {
  background-color: #f7dc51 !important;
  color: #121d1c !important;
  border-radius: 10px !important;
}
.processsed {
  background-color: #b4ead3 !important;
  color: #121d1c !important;
  border-radius: 15px !important;
  padding: 8px 30px !important;
  font-weight: bold;
}

@media only screen and (max-width: 480px) {
  .main-card {
    padding: 5px !important;
  }

  .custom-timesheetdt-title h6 {
    font-weight: 900;
    font-size: 15px !important;
  }

  .timesheetdt-details p {
    margin-bottom: 3px !important;
    font-size: 14px;
    padding-left: 6px !important;
  }

  .pending {
    background-color: #f7dc51 !important;
    color: #121d1c !important;
    border-radius: 10px !important;
  }
  .processsed {
    background-color: #b4ead3 !important;
    color: #121d1c !important;
    border-radius: 10px !important;
    padding: 5px 30px !important;
    font-weight: bold;
  }
}
`, "",{"version":3,"sources":["webpack://./src/pages/Timesheet/timesheet.css"],"names":[],"mappings":"AAAA;EACE,gBAAgB;AAClB;;AAEA;EACE,gBAAgB;AAClB;;AAEA;EACE,gBAAgB;EAChB,0BAA0B;AAC5B;AACA;EACE,6BAA6B;EAC7B,eAAe;EACf,4BAA4B;AAC9B;;AAEA;EACE,oCAAoC;EACpC,yBAAyB;EACzB,8BAA8B;AAChC;AACA;EACE,oCAAoC;EACpC,yBAAyB;EACzB,8BAA8B;EAC9B,4BAA4B;EAC5B,iBAAiB;AACnB;;AAEA;EACE;IACE,uBAAuB;EACzB;;EAEA;IACE,gBAAgB;IAChB,0BAA0B;EAC5B;;EAEA;IACE,6BAA6B;IAC7B,eAAe;IACf,4BAA4B;EAC9B;;EAEA;IACE,oCAAoC;IACpC,yBAAyB;IACzB,8BAA8B;EAChC;EACA;IACE,oCAAoC;IACpC,yBAAyB;IACzB,8BAA8B;IAC9B,4BAA4B;IAC5B,iBAAiB;EACnB;AACF","sourcesContent":[".custom-timesheetdt-row {\n  margin: 10px 0px;\n}\n\n.main-card {\n  margin: 10px 0px;\n}\n\n.custom-timesheetdt-title h6 {\n  font-weight: 900;\n  font-size: 20px !important;\n}\n.timesheetdt-details p {\n  margin-bottom: 3px !important;\n  font-size: 18px;\n  padding-left: 6px !important;\n}\n\n.pending {\n  background-color: #f7dc51 !important;\n  color: #121d1c !important;\n  border-radius: 10px !important;\n}\n.processsed {\n  background-color: #b4ead3 !important;\n  color: #121d1c !important;\n  border-radius: 15px !important;\n  padding: 8px 30px !important;\n  font-weight: bold;\n}\n\n@media only screen and (max-width: 480px) {\n  .main-card {\n    padding: 5px !important;\n  }\n\n  .custom-timesheetdt-title h6 {\n    font-weight: 900;\n    font-size: 15px !important;\n  }\n\n  .timesheetdt-details p {\n    margin-bottom: 3px !important;\n    font-size: 14px;\n    padding-left: 6px !important;\n  }\n\n  .pending {\n    background-color: #f7dc51 !important;\n    color: #121d1c !important;\n    border-radius: 10px !important;\n  }\n  .processsed {\n    background-color: #b4ead3 !important;\n    color: #121d1c !important;\n    border-radius: 10px !important;\n    padding: 5px 30px !important;\n    font-weight: bold;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
