import axios from "axios";
export const ImageUrl = 'https://image.coddapps.com.au/'
export const getApi = () => {
  axios.create({
    baseURL: "https://roster.coddapps.com.au/api/",
    // baseURL: "https://coddgpapi.coddapps.com.au/api/",
    headers: {
      "Content-Type": "application/json",
      Validate: "y2s4pyj52nzr49jnuxxgqk5jtj28cj",
    },
  });
};
export const getFormApi = () =>
axios.create({

  baseURL: "https://roster.coddapps.com.au/api/",
  // baseURL: "https://coddgpapi.coddapps.com.au/api/",
  headers: {
    'Content-Type': "multipart/form-data",
    Validate: "y2s4pyj52nzr49jnuxxgqk5jtj28cj",

  },
});
