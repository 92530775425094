import React, { useState, useEffect } from "react";
import moment from "moment";
import "./timesheetdetails.css";
import { Card, Col, Row, Modal, Button } from "react-bootstrap";
import { FaPlus } from "react-icons/fa";
import { GoDotFill } from "react-icons/go";
import useMediaQuery from "@mui/material/useMediaQuery";
import { useTheme } from "@mui/material/styles";
import { Divider } from "@mui/material";
import { Link, useLocation } from "react-router-dom";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import CustomSpinner from "../../components/CustomSpinner";
import CustomBackButton from "../../components/CustomBackButton";
import CustomModal from "../../components/CustomModal";

export const getApi = () => {
  return axios.create({
    baseURL: "https://roster.coddapps.com.au/api/",
    headers: {
      "Content-Type": "application/json",
      Validate: "y2s4pyj52nzr49jnuxxgqk5jtj28cj",
    },
  });
};

const TimesheetDetails = () => {
  const [loading, setLoading] = useState(true);
  const [showModal, setShowModal] = useState(false); // Modal state
  const [timesheetDays, setTimesheetDays] = useState([]);
  const [week1Label, setWeek1Label] = useState("");
  const [week2Label, setWeek2Label] = useState("");
  const [oneFourday, setoneFourday] = useState("");

  const [selectedDate, setSelectedDate] = useState("");

  // Define state for week1Data and week2Data
  const [week1Data, setWeek1Data] = useState([]);
  const [week2Data, setWeek2Data] = useState([]);

  const user = useSelector((state) => state.user);
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("md"));

  const location = useLocation(); // Get the location object
  const { startdate } = location.state || {}; // Extract startdate from state

  const [modalContent, setModalContent] = useState({
    title: "",
    message: "",
    onConfirm: () => {},
  });

  const handleOpenModal = (action, date) => {
    setSelectedDate(date); // Set selected date

    if (action === "submit") {
      setModalContent({
        title: "Confirm Submission",
        message: "Are you sure you want to submit?",
        onConfirm: handleSubmit,
      });
    } else if (action === "delete") {
      setModalContent({
        title: "Confirm Deletion",
        message: "Are you sure you want to delete?",
        onConfirm: handleDelete,
      });
    } else if (action === "selectEntry") {
      setModalContent({
        title: "Add Entry for",
        message: (
          <div>
            <h6 className="text-center mb-3">Select entry type</h6>
            <ul>
              <li>
                <Link to="/timesheetdetails">
                  Copy time entries for Tue 1 Oct
                </Link>
              </li>

              {/* <li>
                <Link to="/add-time">Add time</Link>
              </li> */}

              <li>
                <Link to={`/add-time`} state={{ selectedDate: date }}>
                  Add time
                </Link>
              </li>

              <li>
                <Link to={`/add-note`} state={{ selectedDate: date }}>
                  Add note
                </Link>
              </li>

              {/* <li>
                <Link to="/add-note">Add note</Link>
              </li> */}
            </ul>
          </div>
        ),
        onConfirm: handleCloseModal,
      });
    }
    setShowModal(true);
  };

  const handleSubmit = async () => {
    const data = {
      requesttype: "7",
      // locationid: "S01002ANA",
      // userid: "101",
      locationid: user.LocationId,
      userid: user.UserId,
      Startdate: startdate,
    };
    try {
      const response = await getApi().post("Timesheet/", data);
      // console.log("Updated:", response.data);
    } catch (error) {
      console.error("Error:", error);
    }
    console.log("Submitted!");
    setShowModal(false);
  };

  const handleDelete = async () => {
    const data = {
      requesttype: "8",
      // locationid: "S01002ANA",
      // userid: "101",
      locationid: user.LocationId,
      userid: user.UserId,
      Startdate: startdate,
    };
    try {
      const response = await getApi().post("Timesheet/", data);
      // console.log("Deleted:", response.data);

      // After successful deletion, refresh the timesheet details
      await fetchTimesheetDetails();
    } catch (error) {
      console.error("Error:", error);
    } finally {
      setShowModal(false);
    }
    console.log("Deleted!");
  };

  const fetchTimesheetDetails = async () => {
    const data = {
      requesttype: "1",
      // locationid: "S01002ANA",
      // userid: "101",
      locationid: user.LocationId,
      userid: user.UserId,
      Startdate: startdate,
    };

    try {
      setLoading(true);
      const response = await getApi().post("Timesheetdetails/", data);
      console.log("Timesheetdetails Response Data:", response.data.data);

      const daysData = response.data.data;

      setTimesheetDays(response.data.data);

      // Append API response data to week1Label and week2Label
      let week1Data = daysData.slice(0, 7); // Days 0 to 7 (7 days)
      let week2Data = daysData.slice(7, 15); // Days 6 to 12 (7 days)

      setWeek1Data(week1Data);
      setWeek2Data(week2Data);

      setWeek1Label(response.data.Week1Lable);
      setWeek2Label(response.data.Week2Lable);
      setoneFourday(response.data.oneFourday);
    } catch (error) {
      console.error("Error:", error);
    } finally {
      setLoading(false); // End loading
    }
  };

  useEffect(() => {
    fetchTimesheetDetails();
  }, []);

  const handleCloseModal = () => {
    setShowModal(false); // Close modal
  };

  if (loading) {
    return <CustomSpinner />;
  }

  return (
    <>
      <Row>
        <div className="text-end">
          <CustomBackButton />
        </div>

        <Col xs={0} sm={0}></Col>
        <Col xs={12} sm={12} className="custom-timesheet-row">
          <Card className="main-card mb-3 head-text">
            <h5>Timesheet Details</h5>
            <h5>{oneFourday}</h5>
          </Card>

          {/* first week */}
          <Card className="main-card">
            {isMobile ? (
              // Displaying in mobile view
              <>
                <div className="format-text-mb mt-2">
                  {/* <h5 className="fw-bold">{oneFourday}</h5> */}
                  <h5 className="fw-bold">{week1Label}</h5>
                </div>

                <Card className="custom-card-mb">
                  <div className="custom-table-div">
                    {week1Data.map((dayItem, index) => (
                      <>
                        <div key={index}>
                          <Row className="custom-day-row">
                            <Col xs={6}>
                              <div className="day-label-mb fw-bold">
                                <GoDotFill className="day-icon" />{" "}
                                {dayItem.Day_Date_Month}
                              </div>
                            </Col>
                            <Col xs={4}>
                              {dayItem.TOTALTIME && (
                                <div className="day-label-mb fw-bold">
                                  <p className="text-end">
                                    {dayItem.TOTALTIME} <br></br>{" "}
                                    <span>Total hours</span>{" "}
                                  </p>
                                </div>
                              )}
                            </Col>
                            <Col xs={2}>
                              <FaPlus
                                className="plus-icon"
                                onClick={() =>
                                  handleOpenModal(
                                    "selectEntry",
                                    dayItem.Day_Date_Month
                                  )
                                }
                              />
                            </Col>
                          </Row>

                          <Row className="mb-timedate">
                            <Col xs={7}>
                              {(dayItem.START_TIME || dayItem.END_TIME) && (
                                <div>
                                  <p>
                                    Work {dayItem.START_TIME} -{" "}
                                    {dayItem.END_TIME}
                                  </p>
                                </div>
                              )}
                            </Col>
                            <Col xs={5}>
                              {dayItem.WORK && (
                                <div className="text-center">
                                  <p>{dayItem.WORK}hours</p>
                                </div>
                              )}
                            </Col>
                          </Row>

                          <Row className="mb-timedate">
                            <Col xs={7}>
                              {(dayItem.BREAK_STIME || dayItem.BREAK_ETIME) && (
                                <div>
                                  <p>
                                    Break {dayItem.BREAK_STIME} -{" "}
                                    {dayItem.BREAK_ETIME}
                                  </p>
                                </div>
                              )}
                            </Col>
                            <Col xs={5}>
                              {dayItem.BREAK && (
                                <div className="text-center">
                                  <p>{dayItem.BREAK}hours</p>
                                </div>
                              )}
                            </Col>
                          </Row>
                          <Row className="mb-timedate">
                            <Col xs={7}>
                              {(dayItem.OVER_STIME || dayItem.OVER_ETIME) && (
                                <div>
                                  <p>
                                    Overtime {dayItem.OVER_STIME} -{" "}
                                    {dayItem.OVER_ETIME}
                                  </p>
                                </div>
                              )}
                            </Col>
                            <Col xs={5}>
                              {dayItem.OVERTIME && (
                                <div className="text-center">
                                  <p>{dayItem.OVERTIME} hours</p>
                                </div>
                              )}
                            </Col>
                          </Row>
                        </div>
                        <Divider className="mb-custom-divider" />
                      </>
                    ))}
                  </div>
                </Card>

              </>
            ) : (
              // Web view
              <>
                <div className="mt-4 mb-4">
                  {/* <h5 className="fw-bold">{oneFourday}</h5> */}
                  <h5 className="fw-bold">{week1Label}</h5>
                </div>

                <Card>
                  <div className="custom-table-div">
                    {week1Data.map((dayItem, index) => (
                      <div key={index}>
                        <Row className="mb-3">
                          <Col
                            sm={2}
                            style={{ width: "15%", minHeight: "80px" }}
                          >
                            <div className="day-label">
                              <GoDotFill className="day-icon" />{" "}
                              {dayItem.Day_Date_Month}
                            </div>
                          </Col>

                          <Col
                            sm={3}
                            style={{ width: "21%", minHeight: "80px" }}
                          >
                            {(dayItem.START_TIME ||
                              dayItem.END_TIME ||
                              dayItem.WORK) && (
                              <div>
                                <p>
                                  Work {dayItem.START_TIME} - {dayItem.END_TIME}
                                </p>
                                <p> {dayItem.WORK} hours</p>
                              </div>
                            )}
                          </Col>

                          <Col
                            sm={3}
                            style={{ width: "21%", minHeight: "80px" }}
                          >
                            {(dayItem.BREAK_STIME ||
                              dayItem.BREAK_ETIME ||
                              dayItem.BREAK) && (
                              <div>
                                <p>
                                  Break {dayItem.BREAK_STIME} -{" "}
                                  {dayItem.BREAK_ETIME}
                                </p>
                                <p> {dayItem.BREAK} hours</p>
                              </div>
                            )}
                          </Col>
                          <Col
                            sm={3}
                            style={{ width: "21%", minHeight: "80px" }}
                          >
                            {(dayItem.OVER_STIME ||
                              dayItem.OVER_ETIME ||
                              dayItem.OVERTIME) && (
                              <div>
                                <p>
                                  Overtime {dayItem.OVER_STIME} -{" "}
                                  {dayItem.OVER_ETIME}
                                </p>

                                <p>{dayItem.OVERTIME} hours</p>
                              </div>
                            )}
                          </Col>
                          <Col
                            sm={2}
                            style={{ width: "13%", minHeight: "80px" }}
                          >
                            {dayItem.TOTALTIME && (
                              <div className="custom-leave-type">
                                <p>{dayItem.TOTALTIME} Total hours </p>
                              </div>
                            )}
                          </Col>
                          <Col sm={1} style={{ minHeight: "80px" }}>
                            <div className="text-center">
                              <FaPlus
                                className="plus-icon"
                                onClick={() =>
                                  handleOpenModal(
                                    "selectEntry",
                                    dayItem.Day_Date_Month
                                  )
                                }
                              />
                            </div>
                          </Col>

                          <Divider sx={{ bgcolor: "#1959bb" }} />
                        </Row>
                      </div>
                    ))}
                  </div>
                </Card>
              </>

            )}
          </Card>

          {/* second week */}

          <Card className="main-card mt-2">
            {isMobile ? (
              // Displaying in mobile view
              <>
                <div className="format-text-mb mt-2">
                  {/* <h5 className="fw-bold">{oneFourday}</h5> */}
                  <h5 className="fw-bold">{week2Label}</h5>
                </div>

                <Card className="custom-card-mb">
                  <div className="custom-table-div">
                    {week2Data.map((dayItem, index) => (
                      <>
                        <div key={index}>
                          <Row
                            className="custom-day-row"
                            onClick={() =>
                              handleOpenModal(
                                "selectEntry",
                                dayItem.Day_Date_Month
                              )
                            }
                          >
                            <Col xs={6}>
                              <div className="day-label-mb fw-bold">
                                <GoDotFill className="day-icon" />{" "}
                                {dayItem.Day_Date_Month}
                              </div>
                            </Col>
                            <Col xs={4}>
                              {dayItem.TOTALTIME && (
                                <div className="day-label-mb fw-bold">
                                  <p className="text-end">
                                    {dayItem.TOTALTIME} <br></br>{" "}
                                    <span>Total hours</span>{" "}
                                  </p>
                                </div>
                              )}
                            </Col>
                            <Col xs={2}>
                              <FaPlus
                                className="plus-icon"
                                onClick={() => handleOpenModal("selectEntry")}
                              />
                            </Col>
                          </Row>

                          <Row className="mb-timedate">
                            <Col xs={7}>
                              {(dayItem.START_TIME || dayItem.END_TIME) && (
                                <div>
                                  <p>
                                    Work {dayItem.START_TIME} -{" "}
                                    {dayItem.END_TIME}
                                  </p>
                                </div>
                              )}
                            </Col>
                            <Col xs={5}>
                              {dayItem.WORK && (
                                <div className="text-center">
                                  <p>{dayItem.WORK} hours</p>
                                </div>
                              )}
                            </Col>
                          </Row>

                          <Row className="mb-timedate">
                            <Col xs={7}>
                              {(dayItem.BREAK_STIME || dayItem.BREAK_ETIME) && (
                                <div>
                                  <p>
                                    Break {dayItem.BREAK_STIME} -{" "}
                                    {dayItem.BREAK_ETIME}
                                  </p>
                                </div>
                              )}
                            </Col>
                            <Col xs={5}>
                              {dayItem.BREAK && (
                                <div className="text-center">
                                  <p>{dayItem.BREAK}hours</p>
                                </div>
                              )}
                            </Col>
                          </Row>
                          <Row className="mb-timedate">
                            <Col xs={7}>
                              {(dayItem.OVER_STIME || dayItem.OVER_ETIME) && (
                                <div>
                                  <p>
                                    Overtime {dayItem.OVER_STIME} -{" "}
                                    {dayItem.OVER_ETIME}
                                  </p>
                                </div>
                              )}
                            </Col>
                            <Col xs={5}>
                              {dayItem.OVERTIME && (
                                <div className="text-center">
                                  <p>{dayItem.OVERTIME} hours</p>
                                </div>
                              )}
                            </Col>
                          </Row>
                        </div>
                        <Divider className="mb-custom-divider" />
                      </>
                    ))}
                  </div>
                </Card>
              </>
            ) : (
              // Web view
              <>
                <div className="mt-4 mb-4">
                  {/* <h5 className="fw-bold">{oneFourday}</h5> */}
                  <h5 className="fw-bold">{week2Label}</h5>
                </div>

                <Card>
                  <div className="custom-table-div">
                    {week2Data.map((dayItem, index) => (
                      <div key={index}>
                        <Row className="mb-3">
                          <Col
                            sm={2}
                            style={{ width: "15%", minHeight: "80px" }}
                          >
                            <div className="day-label">
                              <GoDotFill className="day-icon" />{" "}
                              {dayItem.Day_Date_Month}
                            </div>
                          </Col>

                          <Col
                            sm={3}
                            style={{ width: "21%", minHeight: "80px" }}
                          >
                            {(dayItem.START_TIME ||
                              dayItem.END_TIME ||
                              dayItem.WORK) && (
                              <div>
                                <p>
                                  Work {dayItem.START_TIME} - {dayItem.END_TIME}
                                </p>
                                <p> {dayItem.WORK}hours</p>
                              </div>
                            )}
                          </Col>

                          <Col
                            sm={3}
                            style={{ width: "21%", minHeight: "80px" }}
                          >
                            {(dayItem.BREAK_STIME ||
                              dayItem.BREAK_ETIME ||
                              dayItem.BREAK) && (
                              <div>
                                <p>
                                  Break {dayItem.BREAK_STIME} -{" "}
                                  {dayItem.BREAK_ETIME}
                                </p>
                                <p> {dayItem.BREAK}hours</p>
                              </div>
                            )}
                          </Col>
                          <Col
                            sm={3}
                            style={{ width: "21%", minHeight: "80px" }}
                          >
                            {(dayItem.OVER_STIME ||
                              dayItem.OVER_ETIME ||
                              dayItem.OVERTIME) && (
                              <div>
                                <p>
                                  Overtime {dayItem.OVER_STIME} -{" "}
                                  {dayItem.OVER_ETIME}
                                </p>

                                <p>{dayItem.OVERTIME} hours</p>
                              </div>
                            )}
                          </Col>
                          <Col
                            sm={2}
                            style={{ width: "13%", minHeight: "80px" }}
                          >
                            {dayItem.TOTALTIME && (
                              <div className="custom-leave-type">
                                <p>{dayItem.TOTALTIME} Total hours </p>
                              </div>
                            )}
                          </Col>
                          <Col sm={1} style={{ minHeight: "80px" }}>
                            <div className="text-center">
                              <FaPlus
                                className="plus-icon"
                                onClick={() =>
                                  handleOpenModal(
                                    "selectEntry",
                                    dayItem.Day_Date_Month
                                  )
                                }
                              />
                            </div>
                          </Col>

                          <Divider sx={{ bgcolor: "#1959bb" }} />
                        </Row>
                      </div>
                    ))}
                  </div>
                </Card>
              </>
            )}
          </Card>
          
        </Col>
        <Col xs={0} sm={0}></Col>
      </Row>

      <div className="action-buttons">
        <Button
          className="submit-button"
          onClick={() => handleOpenModal("submit")}
        >
          Timesheet Submit
        </Button>
        <Button
          className="delete-button"
          onClick={() => handleOpenModal("delete")}
        >
          Clear Timesheet
        </Button>
      </div>

      <CustomModal
        show={showModal}
        handleClose={handleCloseModal}
        title={modalContent.title}
        message={modalContent.message}
        onConfirm={modalContent.onConfirm}
      />
    </>
  );
};

export default TimesheetDetails;
