import React from "react";
import { Route, Routes, Navigate } from "react-router-dom";
import { useSelector } from "react-redux";
import Sidenav from "./components/Sidenav";
import Login from "./pages/Login";
import MapLocation from "./pages/MapLocation";
import Devicelocation from "./pages/Devicelocation";
import Logout from "./pages/Logout";

function App() {
  const { isAuthenticated } = useSelector((state) => state.auth);

  return (
    <div>
      {/* Show Sidenav only when authenticated */}
      {isAuthenticated && <Sidenav />}

      <Routes>
        {/* Public Routes */}
        <Route path="/login" element={<Login />} />
        <Route path="/logout" element={<Logout />} />

        {/* Protected Routes */}
        {isAuthenticated ? (
          <>
            <Route path="/attendance" element={<MapLocation />} />
            <Route path="/devicelocation" element={<Devicelocation />} />
          </>
        ) : (
          <Route path="*" element={<Navigate to="/login" replace />} />
        )}
      </Routes>
    </div>
  );
}

export default App;
