// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.main-card-addtime {
  /* padding: 5px 20px 5px 5px !important; */
  padding-left: 20px !important;
  padding-bottom: 10px !important;
}

.addtime-custom-form {
  width: 50% !important;
}

@media only screen and (max-width: 480px) {
  .addtime-custom-form {
    width: 80% !important;
  }
}
`, "",{"version":3,"sources":["webpack://./src/pages/Addtime/addtime.css"],"names":[],"mappings":"AAAA;EACE,0CAA0C;EAC1C,6BAA6B;EAC7B,+BAA+B;AACjC;;AAEA;EACE,qBAAqB;AACvB;;AAEA;EACE;IACE,qBAAqB;EACvB;AACF","sourcesContent":[".main-card-addtime {\n  /* padding: 5px 20px 5px 5px !important; */\n  padding-left: 20px !important;\n  padding-bottom: 10px !important;\n}\n\n.addtime-custom-form {\n  width: 50% !important;\n}\n\n@media only screen and (max-width: 480px) {\n  .addtime-custom-form {\n    width: 80% !important;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
