import React from "react";
import Button from "@mui/material/Button";
import { MdOutlineUndo } from "react-icons/md";
import { useNavigate } from "react-router-dom";

const CustomBackButton = () => {
  const navigate = useNavigate(); // Initialize the navigate function

  const handleBackClick = () => {
    navigate(-1); // Navigate to the previous route
  };
  return (
    <div>
      <Button
        onClick={handleBackClick}
        style={{
          backgroundColor: "#41c0f0",
          height: "36px",
          minWidth: "32px",
          borderRadius: "4px",
          padding: "4px 16px",
          margin: "4px 0px",
          borderColor: "#41c0f0",
          color: "white",
        }}
      >
        <MdOutlineUndo style={{ fontSize: "1.5rem" }} />
      </Button>
    </div>
  );
};

export default CustomBackButton;
