// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.rosterdatanew-seconddiv,
.rosterdatanew-thriddiv {
  padding-left: 5rem !important;
}

.dots-icon {
  color: #1959bc !important;
  cursor: pointer;
  font-size: 18px !important;
}

.content-card-practice {
  background-color: white !important;
}

.autocomplete-option {
  margin-top: 8px !important;
  margin-bottom: 8px !important;
}

.option-text {
  margin-left: 16px !important;
}

.content-card-practice {
  border-radius: 0% !important;
  margin: 0px !important;
}

.autocomplete-container {
  width: 100%;
  max-width: 100%;
  margin: 0 auto !important;
}

@media only screen and (max-width: 480px) {
  .rosterdatanew-seconddiv,
  .rosterdatanew-thriddiv {
    padding-left: 0rem !important;
  }
  .autocomplete-container {
    width: 90% !important;
  }
}
`, "",{"version":3,"sources":["webpack://./src/pages/attendance.css"],"names":[],"mappings":"AAAA;;EAEE,6BAA6B;AAC/B;;AAEA;EACE,yBAAyB;EACzB,eAAe;EACf,0BAA0B;AAC5B;;AAEA;EACE,kCAAkC;AACpC;;AAEA;EACE,0BAA0B;EAC1B,6BAA6B;AAC/B;;AAEA;EACE,4BAA4B;AAC9B;;AAEA;EACE,4BAA4B;EAC5B,sBAAsB;AACxB;;AAEA;EACE,WAAW;EACX,eAAe;EACf,yBAAyB;AAC3B;;AAEA;EACE;;IAEE,6BAA6B;EAC/B;EACA;IACE,qBAAqB;EACvB;AACF","sourcesContent":[".rosterdatanew-seconddiv,\n.rosterdatanew-thriddiv {\n  padding-left: 5rem !important;\n}\n\n.dots-icon {\n  color: #1959bc !important;\n  cursor: pointer;\n  font-size: 18px !important;\n}\n\n.content-card-practice {\n  background-color: white !important;\n}\n\n.autocomplete-option {\n  margin-top: 8px !important;\n  margin-bottom: 8px !important;\n}\n\n.option-text {\n  margin-left: 16px !important;\n}\n\n.content-card-practice {\n  border-radius: 0% !important;\n  margin: 0px !important;\n}\n\n.autocomplete-container {\n  width: 100%;\n  max-width: 100%;\n  margin: 0 auto !important;\n}\n\n@media only screen and (max-width: 480px) {\n  .rosterdatanew-seconddiv,\n  .rosterdatanew-thriddiv {\n    padding-left: 0rem !important;\n  }\n  .autocomplete-container {\n    width: 90% !important;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
