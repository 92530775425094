import React, { useEffect } from "react";
import { useNavigate } from "react-router-dom";

const Logout = () => {
  const navigate = useNavigate();

  useEffect(() => {
    const timer = setTimeout(() => {
      navigate("/login");
    }, 500);

    return () => clearTimeout(timer);
  }, [navigate]);

  return <div></div>;
};

export default Logout;
