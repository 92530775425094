import React from "react";
import { Modal, Button, Row, Col } from "react-bootstrap";
import customCloseButtonImage from "../assets/images/close-button1.png";

const TimeModal = ({ show, handleClose, selectedItem }) => {
  const formatTime = (timeString) => {
    if (!timeString) return;
    const [, time] = timeString.split(" ");
    return time;
  };

  return (
    <Modal
      show={show}
      onHide={handleClose}
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <Modal.Header>
        <Modal.Title style={{ fontSize: "16px", fontWeight: "bold" }}>
          Time Page
        </Modal.Title>
        <img
          src={customCloseButtonImage}
          alt="Close"
          onClick={handleClose}
          style={{
            cursor: "pointer",
            marginLeft: "auto",
            height: "20px",
          }}
        />
      </Modal.Header>
      <Modal.Body>
        {selectedItem ? (
          <>
            
            <Row className="py-2">
              <Col style={{ fontWeight: "bold" }}>Roster Start</Col>
              <Col style={{ fontSize: "16px" }}>
                {selectedItem.view_data.Roster_Start}
              </Col>
            </Row>
            <Row className="py-2">
              <Col style={{ fontWeight: "bold" }}>Roster End</Col>
              <Col style={{ fontSize: "16px" }}>
                {selectedItem.view_data.Roster_End}
              </Col>
            </Row>
            <Row className="py-2">
              <Col style={{ fontWeight: "bold" }}>Checkin</Col>
              <Col style={{ fontSize: "16px" }}>
                {formatTime(selectedItem.view_data.Check_Start)}
              </Col>
            </Row>
            <Row className="py-2">
              <Col style={{ fontWeight: "bold" }}>Checkout</Col>
              <Col style={{ fontSize: "16px" }}>
                {formatTime(selectedItem.view_data.Check_End)}
              </Col>
            </Row>
            <Row className="py-2">
              <Col style={{ fontWeight: "bold" }}>Timesheet Start</Col>
              <Col style={{ fontSize: "16px" }}>
                {selectedItem.view_data.Timesheet_start}
              </Col>
            </Row>
            <Row className="py-2">
              <Col style={{ fontWeight: "bold" }}>Timesheet End</Col>
              <Col style={{ fontSize: "16px" }}>
                {selectedItem.view_data.Timesheet_end}
              </Col>
            </Row>
          </>
        ) : (
          <p>No data available.</p>
        )}
      </Modal.Body>
      <Modal.Footer>
        <Button variant="secondary" onClick={handleClose}>
          Close
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default TimeModal;
