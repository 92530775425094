import React, { useEffect, useState } from "react";
import { Card, Col, Row, Badge } from "react-bootstrap";
import "./timesheet.css";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import CustomSpinner from "../../components/CustomSpinner";

export const getApi = () => {
  return axios.create({
    baseURL: "https://roster.coddapps.com.au/api/",
    headers: {
      "Content-Type": "application/json",
      Validate: "y2s4pyj52nzr49jnuxxgqk5jtj28cj",
    },
  });
};

const Timesheet = () => {
  const [timesheetData, setTimesheetData] = useState([]);
  const [loading, setLoading] = useState(true);
  const user = useSelector((state) => state.user);
  const dispatch = useDispatch();
  const navigate = useNavigate();

  // console.log("All API Details", user);

  const fetchTimesheetDraft = async () => {
    const data = {
      requesttype: "6",
      // locationid: "S01002ANA",
      // userid: "101",
      locationid: user.LocationId,
      userid: user.UserId,
    };

    try {
      setLoading(true);
      const response = await getApi().post("Timesheet/", data);
      console.log("Timesheet Draft Response Data:", response.data.data);

      // Assuming response.data.data contains an array of timesheet objects
      setTimesheetData(response.data.data);
    } catch (error) {
      console.error("Error:", error);
    } finally {
      setLoading(false); // End loading
    }
  };

  useEffect(() => {
    fetchTimesheetDraft();
  }, []);

  const handleCardClick = (monthDetail) => {
    const dateToPass = monthDetail[0];
    // console.log("Selected Card Month Detail:", dateToPass);
    navigate("/timesheetdetails", { state: { startdate: dateToPass } });
  };

  const formatHours = (timeString) => {
    return timeString.split(":").slice(0, 2).join(":");
  };

  if (loading) {
    return <CustomSpinner />;
  }

  return (
    <>
      <Row>
        <Col xs={12} sm={0}></Col>

        {timesheetData.map((item, index) => (
          <Col key={index} xs={12} sm={12} className="custom-timesheetdt-row">
            <div className="custom-timesheetdt-title">
              <h6>{item.month_year}</h6>
            </div>

            {item.Monthdata.map((monthDetail, monthIndex) => (
              <Card
                key={monthIndex}
                className="main-card"
                onClick={() => handleCardClick(monthDetail)}
                style={{ cursor: "pointer" }}
              >
                <div className="timesheetdt-details">
                  <h5>{monthDetail[2]}</h5>
                  <p>Total Hours - {formatHours(monthDetail[4])}</p>
                  <p>
                    <Badge pill bg="secondary" className="processsed">
                      {monthDetail[3]}
                    </Badge>
                  </p>
                </div>
              </Card>
            ))}
          </Col>
        ))}

        <Col xs={12} sm={0}></Col>
      </Row>
    </>
  );
};

export default Timesheet;
