// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `/* roster.css */
.custom-table-div {
  margin: 15px 15px 15px 15px !important;
}

.custom-table-header th {
  background-color: #1959bc !important;
  color: white !important;
  text-align: center;
  padding: 15px;
}

.table-row td {
  padding: 15px;
}

.table-responsive {
  border-top-left-radius: 5px !important;
  border-top-right-radius: 5px !important;
}

/* Specific border-radius for Date and Duration */
.custom-table-header th:first-child {
  border-top-left-radius: 5px; /* Top-left corner */
}

.custom-table-header th:last-child {
  border-top-right-radius: 5px; /* Top-right corner */
}

.roster-btn {
  background-color: #1959bc !important;
  border: none;
  color: white;
  width: 120px;
  padding: 10px;
}

@media only screen and (max-width: 480px) {
  .custom-table-div {
    margin: 0px 7px 10px 7px !important;
  }
  .custom-table-div h5 {
    font-size: 16px !important;
  }
  .custom-table-header {
    font-size: 15px !important;
  }
  .table-row td {
    font-size: 15px !important;
  }
}
`, "",{"version":3,"sources":["webpack://./src/pages/Roster/roster.css"],"names":[],"mappings":"AAAA,eAAe;AACf;EACE,sCAAsC;AACxC;;AAEA;EACE,oCAAoC;EACpC,uBAAuB;EACvB,kBAAkB;EAClB,aAAa;AACf;;AAEA;EACE,aAAa;AACf;;AAEA;EACE,sCAAsC;EACtC,uCAAuC;AACzC;;AAEA,iDAAiD;AACjD;EACE,2BAA2B,EAAE,oBAAoB;AACnD;;AAEA;EACE,4BAA4B,EAAE,qBAAqB;AACrD;;AAEA;EACE,oCAAoC;EACpC,YAAY;EACZ,YAAY;EACZ,YAAY;EACZ,aAAa;AACf;;AAEA;EACE;IACE,mCAAmC;EACrC;EACA;IACE,0BAA0B;EAC5B;EACA;IACE,0BAA0B;EAC5B;EACA;IACE,0BAA0B;EAC5B;AACF","sourcesContent":["/* roster.css */\n.custom-table-div {\n  margin: 15px 15px 15px 15px !important;\n}\n\n.custom-table-header th {\n  background-color: #1959bc !important;\n  color: white !important;\n  text-align: center;\n  padding: 15px;\n}\n\n.table-row td {\n  padding: 15px;\n}\n\n.table-responsive {\n  border-top-left-radius: 5px !important;\n  border-top-right-radius: 5px !important;\n}\n\n/* Specific border-radius for Date and Duration */\n.custom-table-header th:first-child {\n  border-top-left-radius: 5px; /* Top-left corner */\n}\n\n.custom-table-header th:last-child {\n  border-top-right-radius: 5px; /* Top-right corner */\n}\n\n.roster-btn {\n  background-color: #1959bc !important;\n  border: none;\n  color: white;\n  width: 120px;\n  padding: 10px;\n}\n\n@media only screen and (max-width: 480px) {\n  .custom-table-div {\n    margin: 0px 7px 10px 7px !important;\n  }\n  .custom-table-div h5 {\n    font-size: 16px !important;\n  }\n  .custom-table-header {\n    font-size: 15px !important;\n  }\n  .table-row td {\n    font-size: 15px !important;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
