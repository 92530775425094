import React from "react";
import { Spinner } from "react-bootstrap";

const CustomSpinner = () => {
  return (
    <div style={styles.spinnerContainer}>
      <Spinner animation="border" variant="primary" style={styles.spinner} />
    </div>
  );
};

const styles = {
  spinnerContainer: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    height: "50vh", 
    backgroundColor: "#f8f9fa",
  },
  spinner: {
    width: "3rem",
    height: "3rem",
  },
};

export default CustomSpinner;
