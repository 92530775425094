import React, { useEffect, useState } from "react";
import Card from "react-bootstrap/Card";
import axios from "axios";
import { Col, Row } from "react-bootstrap";
import { Table } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import "./roster.css";
import CustomSpinner from "../../components/CustomSpinner";

export const getApi = () => {
  return axios.create({
    baseURL: "https://roster.coddapps.com.au/api/",
    headers: {
      "Content-Type": "application/json",
      Validate: "y2s4pyj52nzr49jnuxxgqk5jtj28cj",
    },
  });
};

const Roster = () => {
  const [loading, setLoading] = useState(true);
  const [rosterData, setRosterData] = useState([]);
  const [weekData1, setWeekData1] = useState([]); // State to store Week_data_1
  const [weekData2, setWeekData2] = useState([]); // State to store Week_data_2
  const user = useSelector((state) => state.user);
  const dispatch = useDispatch();

  useEffect(() => {
    setLoading(true);
    const fetchRosterData = async () => {
      try {
        const response = await getApi().post("RosterdataWeek/", {
          locationid: user.LocationId,
          userid: user.UserId,
        });

        // Store both weeks data in state
        setWeekData1(response.data.Week_data_1);
        setWeekData2(response.data.Week_data_2);
        setRosterData(response.data.Week_data_1); // Set initial data to Week_data_1

        // console.log(response.data.Week_data_1); // Logging API data for debugging
        // console.log(response.data.Week_data_2); // Logging API data for debugging
      } catch (error) {
        console.error("Error fetching roster data:", error);
      } finally {
        setLoading(false);
      }
    };

    fetchRosterData();
  }, [user.LocationId, user.UserId]); // Added user dependencies

  if (loading) {
    return <CustomSpinner />;
  }

  return (
    <>
      <Row>
        <Col sm={1}></Col>
        <Col sm={10} className="custom-roster-row">
          <Card className="main-card">
            {/* Current Week Section */}

            <div className="custom-table-div">
              <h5 className="text-start mt-3 mb-4 fw-bold">Current Week</h5>
              <Table responsive bordered>
                <thead className="custom-table-header">
                  <tr>
                    <th>Date</th>
                    <th>Time</th>
                    <th>Duration</th>
                  </tr>
                </thead>
                <tbody>
                  {weekData1.length > 0 ? (
                    weekData1.map((data, index) => (
                      <tr key={`week1-${index}`} className="table-row">
                        <td>{data.SDATE_Name}</td>
                        <td>
                          {data.Starttime && data.Endtime
                            ? `${data.Starttime} to ${data.Endtime}`
                            : "-"}
                        </td>
                        <td>
                          {data.Totaltime && data.Totaltime !== "0"
                            ? `${data.Totaltime} hours`
                            : "-"}
                        </td>
                      </tr>
                    ))
                  ) : (
                    <tr>
                      <td colSpan="3" className="text-center">
                        No data available for Current Week
                      </td>
                    </tr>
                  )}
                </tbody>
              </Table>
            </div>

            {/* Next Week Section */}

            <div className="custom-table-div">
              <h5 className="text-start mt-1 mb-4 fw-bold">Next Week</h5>
              <Table responsive bordered>
                <thead className="custom-table-header">
                  <tr>
                    <th>Date</th>
                    <th>Time</th>
                    <th>Duration</th>
                  </tr>
                </thead>

                <tbody>
                  {weekData2.length > 0 ? (
                    weekData2.map((data, index) => (
                      <tr key={`week2-${index}`} className="table-row">
                        <td>{data.SDATE_Name}</td>
                        <td>
                          {data.Starttime && data.Endtime
                            ? `${data.Starttime} to ${data.Endtime}`
                            : "-"}
                        </td>
                        <td>
                          {data.Totaltime && data.Totaltime !== "0"
                            ? `${data.Totaltime} hours`
                            : "-"}
                        </td>
                      </tr>
                    ))
                  ) : (
                    <tr>
                      <td colSpan="3" className="text-center">
                        No data available for Current Week
                      </td>
                    </tr>
                  )}
                </tbody>
              </Table>
            </div>
            
          </Card>
        </Col>
        <Col sm={1}></Col>
      </Row>
    </>
  );
};

export default Roster;
