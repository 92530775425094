import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  isAuthenticated: localStorage.getItem("isAuthenticated") === "true",
  user: null,
};

const authSlice = createSlice({
  name: "auth",
  initialState,
  reducers: {
    // login: (state, action) => {
    //   state.isAuthenticated = true;
    //   state.user = action.payload;
    //   localStorage.setItem('isAuthenticated', 'true');
    // },
    login: (state, action) => {
      state.isAuthenticated = true;
      state.user = {
        username: action.payload.username,
        password: action.payload.password,
      };
      localStorage.setItem("isAuthenticated", "true");
      localStorage.setItem("username", action.payload.username);
      localStorage.setItem("password", action.payload.password);
    },

    logout: (state) => {
      state.isAuthenticated = false;
      state.location_id = "";
      state.userid = "";
      localStorage.setItem("isAuthenticated", "false");
    },
    register: (state, action) => {
      state.isAuthenticated = true;
      state.user = action.payload;
    },
  },
});

export const { login, logout, register } = authSlice.actions;
export const selectAuth = (state) => state.auth;
export default authSlice.reducer;
