import React from "react";
import Modal from "react-bootstrap/Modal";
import Button from "react-bootstrap/Button";

const DistanceAlertModal = ({
  show,
  onClose,
  latitude,
  longitude,
  isMapLocation,
}) => {
  console.log("Latitude and Longitude in Modal: ", latitude, longitude);

  const handleOpenMap = () => {
    if (latitude && longitude) {
      console.log("Opening map with coordinates:", latitude, longitude);
      const mapUrl = `https://maps.google.com/?q=${latitude},${longitude}`;
      window.open(mapUrl, "_blank"); // Open in a new tab
    } else {
      console.log("Latitude and longitude are not available in handleOpenMap");
    }
  };

  return (
    <Modal className="mt-5" show={show} onHide={onClose}>
      <Modal.Header closeButton>
        <Modal.Title>Distance Alert</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div>
          {" "}
          You are more than 2000 meters away from the hospital. Please get closer
          to check in.
        </div>
        {/* <div>
          <p>
            Latitude: {latitude}, Longitude: {longitude}
          </p>
        </div> */}
      </Modal.Body>
      <Modal.Footer>
        {/* <Button variant="primary" onClick={handleOpenMap}>
          Open Map
        </Button> */}
        {isMapLocation && (
          <Button variant="primary" onClick={handleOpenMap}>
            Open Map
          </Button>
        )}
        <Button variant="secondary" onClick={onClose}>
          Close
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default DistanceAlertModal;
